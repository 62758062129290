<template>
  <div v-if="item" class="p-d-flex p-ai-center">
    <Spinner v-if="showUpdatedItemPdfSpinner && disableEditButton && isItemUpdated" :isSmall="true" spinnerStyle="width: 20px; height: 20px"></Spinner>
    <a @contextmenu.prevent v-else-if="item.file_path" href="#" @click.prevent="getPdf(item.file_path)" class="p-link table-link-icon">
      <img src="/assets/images/pdf_sm_icon.gif" alt="pdf_sm" class="pdf-icon"/>
    </a>
  </div>
</template>

<script>
import httpClient from "@/services/http.services";
import settings from "@/settings";

export default {
  name: 'GetSalesInvoicePdfButtonSM',
  props: {
    item: Object,
    disableEditButton: Boolean,
    isItemUpdated: Boolean,
    showUpdatedItemPdfSpinner: {
      type: Boolean,
      default: false,
    }
  },
  // watch: {
  //   'item.state'() {
  //     console.log(this.item.state)
  //   }
  // },
  methods: {
    async getPdf(filePath) {
      if (!filePath) return false
      try {
        const { status, data } = await httpClient(`sales-invoice/get-pdf?filePath=${filePath}`, {responseType: 'arraybuffer'})
        // const { status, data } = await httpClient(`offer/get-pdf?filePath=${item?.file_path}`, {responseType: 'blob'})
        if (status === 200 && data) {
          let blob = new Blob([data], { type: 'application/pdf' }),
              url = URL.createObjectURL(blob)

          window.open(url, '_blank')
          URL.revokeObjectURL(url);
        }
      } catch(err) {
        this.$toast.add({severity:'error', summary: `404`, detail: 'File not found', life: settings.toastLife})
      }
      // this.isLoading = false
    },
  }
}
</script>

<style scoped>
.pdf-icon {
  display: block;
}
</style>